import React from 'react'
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from '../components/SolutionCandidateExp/Sc1banner'
import FeaturedServices from '../components/SolutionCandidateExp/Sc2featuredServices'
import AboutArea from '../components/SolutionCandidateExp/Sc3aboutArea'
import HowItWork from "../components/SolutionCandidateExp/Sc4howItWork"
import CaseStudy from '../components/SolutionCandidateExp/Sc5caseStudy'
import Form from "../components/SolutionRecruitments/Sa4form"


const InnovativeRecruitingSystemEtExpRienceCandidatInnovante = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <Banner />
            <FeaturedServices />
            <AboutArea />
            <HowItWork />
            <CaseStudy />
            <Form />

            <Footer />
        </Layout>
    )
}

export default InnovativeRecruitingSystemEtExpRienceCandidatInnovante;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

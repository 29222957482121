import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import service3 from "../../assets/images/services/service3.png";
import starIcon from "../../assets/images/star-icon.png";
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png";
import shape1 from "../../assets/images/shape/circle-shape1.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const CEXc = () => {
	const { t } = useTranslation();

	return (
		<section className="about-area pb-100">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12">
						<div className="about-image">
							<img src={service3} alt="about" />
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<div className="about-content">
							<div className="content">
								<span className="sub-title">
									<img src={starIcon} alt="about" />
									{t("home.CEXci")}
								</span>
								<h2>{t("home.CEXch2")}</h2>
								<p>{t("home.CEXcp1")}</p>

								<ul className="about-list">
									<li>
										<i className="flaticon-tick"></i>
										{t("home.CEXci2")}
										<img src={shape2} alt="about" />
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.CEXci3")}
										<img src={shape2} alt="about" />
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.CEXci4")}
										<img src={shape2} alt="about" />
									</li>
									<li>
										<i className="flaticon-tick"></i>
										{t("home.CEXci5")}
										<img src={shape2} alt="about" />
									</li>
								</ul>
								<p>{t("home.CEXcp2")}</p>

								<Link
									to="/selfpromotion-and-talent-community"
									className="default-btn"
								>
									<i className="flaticon-right"></i>
									{t("EnSavoirPlus")}
									<span></span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="circle-shape1">
				<img src={shape1} alt="about" />
			</div>
		</section>
	);
};

export default CEXc;
